<script setup>
import TheHeader from '../components/TheHeader'
import TheFooter from '../components/TheFooter'
import router from '../router'

function goToHomePage() {
  console.log('test')
  router.push({ name: 'home'})
}
</script>

<template>
  <main>
    <TheHeader />
    <section class="container">
      <div class="box">
        <h1>404</h1>
        <h2>Page not found</h2>
        <p>We're sorry, the page you requested could not be found. Please go back to the home page.</p>
        <button
          @click="goToHomePage"
          class="button-55"
        >
          Go Back to Home
        </button>
      </div>
    </section>
  </main>
  <TheFooter />
</template>

<style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    font-family: Arial, sans-serif;
    .box {
      margin: auto;
      text-align: center;
    }
    h1 {
      margin: auto;
      font-size: 80px;
      color: #63d1a2;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #007b56;
    }
    h2 {
      font-size: 40px;
      color: #005232;
    }
    p {
      margin: 15px 20px;
      color: #005232;
    }
    .button-55 {
      align-self: center;
      background-color: #63d1a2;
      background-image: none;
      background-position: 0 90%;
      background-repeat: repeat no-repeat;
      background-size: 4px 3px;
      border-style: solid;
      border-width: 1px;
      box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-family: Arial, sans-serif;
      font-size: 1rem;
      line-height: 23px;
      outline: none;
      padding: .75rem;
      text-decoration: none;
      transition: all 235ms ease-in-out;
      border-radius: 5px;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      border-color: #007b56;
      text-shadow: 1px 1px 1px #007b56;
      margin: 20px;
    }

    .button-55:hover {
      box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
      transform: translate3d(0, 2px, 0);
      background-color: #00AB6D;
    }

    .button-55:focus {
      box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
    }
  }
</style>